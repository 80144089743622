export const translations = {
  hr: {
    hero: {
      title: "Naše Usluge",
      description: "Specijalizirani smo za izradu alata za brizganje plastike, alata za puhanje, alata za štancanje, obradu metala, lasersko rezanje, zavarivanje te obradu plastike i savijanje."
    },
    services: {
      cta: "Saznajte više",
      laser: {
        title: "Lasersko Rezanje",
        description: "Precizno lasersko rezanje metala i plastike s vrhunskom kvalitetom.",
        details: "Lasersko rezanje materijala (metala ili plastike) se vrši usmjeravanjem laserskog mlaza velike izlazne snage direktno na materijal i uz pomoć unaprijed programiranog izgleda uzorka.",
        features: [
          "Precizno rezanje",
          "Brza izrada",
          "Vrhunska kvaliteta"
        ]
      },
      products: {
        title: "Proizvodi od Metala i Plastike",
        description: "Proizvodnja metalnih i plastičnih proizvoda široke namjene.",
        details: "Proizvodimo različite metalne proizvode široke namjene, uključujući laserski rezane, prešane i štancane dijelove, toplinski obrađene komade čelika. Također proizvodimo injekcijske (brizgane) dijelove iz plastike namijenjene daljnjoj doradi i finaliziranju, kao i gotove brizgane plastične dijelove.",
        features: [
          "Metalni proizvodi",
          "Plastični proizvodi",
          "Prilagođena rješenja"
        ],
      },
      tools: {
        title: "Alati i Kalupi",
        description: "Proizvodnja alata za obradu metala i kalupa za plastiku.",
        details: "Proizvodimo razne alate koji služe daljnjoj obradi metala i plastike, uključujući alate za prosijecanje i oblikovanje te alate za ekstruziju. Također izrađujemo kalupe za brizganje plastike i kalupe za puhanje.",
        features: [
          "Alati za metal",
          "Kalupi za plastiku",
          "Stručna izrada"
        ]
      }
    },
    process: {
      title: "Proces Proizvodnje",
      description: "Naš proces proizvodnje je optimiziran za maksimalnu učinkovitost i kvalitetu.",
      steps: [
        {
          title: "Dizajn i Planiranje",
          description: "Detaljno planiranje i dizajn proizvoda prema vašim specifikacijama."
        },
        {
          title: "Proizvodnja",
          description: "Precizna proizvodnja koristeći najnovije tehnologije i strojeve."
        },
        {
          title: "Kontrola Kvalitete",
          description: "Stroga kontrola kvalitete na svakoj razini proizvodnje."
        }
      ]
    },
    cta: {
      title: "Spremni za Suradnju?",
      description: "Kontaktirajte nas i saznajte kako možemo pomoći u razvoju vaših proizvoda.",
      button: "Kontaktirajte Nas"
    }
  },
  en: {
    hero: {
      title: "Our Services",
      description: "We specialize in the production of injection moulds, blow moulds, stamping tools, metal processing, laser cutting, welding, as well as plastic processing and bending."
    },
    services: {
      cta: "Learn More",
      laser: {
        title: "Laser Cutting",
        description: "Precise laser cutting of metal and plastic with superior quality.",
        details: "Laser cutting of materials (metal or plastic) is performed by directing a high-power laser beam directly onto the material using a pre-programmed pattern.",
        features: [
          "Precise cutting",
          "Fast production",
          "Superior quality"
        ]
      },
      products: {
        title: "Metal and Plastic Products",
        description: "Manufacturing of metal and plastic products for various applications.",
        details: "We produce various metal products for general use, including laser-cut, pressed, and stamped parts, heat-treated steel pieces. We also manufacture injection-molded plastic parts for further processing and finishing, as well as finished injection-molded plastic parts.",
        features: [
          "Metal products",
          "Plastic products",
          "Custom solutions"
        ]
      },
      tools: {
        title: "Tools and Molds",
        description: "Manufacturing of metal processing tools and plastic molds.",
        details: "We produce various tools for further processing of metal and plastic, including cutting and shaping tools, and extrusion tools. We also manufacture injection molding molds and blowing molds.",
        features: [
          "Metal tools",
          "Plastic molds",
          "Expert craftsmanship"
        ]
      }
    },
    process: {
      title: "Production Process",
      description: "Our production process is optimized for maximum efficiency and quality.",
      steps: [
        {
          title: "Design and Planning",
          description: "Detailed planning and product design according to your specifications."
        },
        {
          title: "Production",
          description: "Precise production using the latest technologies and machines."
        },
        {
          title: "Quality Control",
          description: "Strict quality control at every level of production."
        }
      ]
    },
    cta: {
      title: "Ready to Collaborate?",
      description: "Contact us and learn how we can help develop your products.",
      button: "Contact Us"
    }
  },
  de: {
    hero: {
      title: "Unsere Dienstleistungen",
      description: "Wir sind spezialisiert auf die Herstellung von Spritzgusswerkzeugen, Blasformen, Stanzwerkzeugen, Metallbearbeitung, Laserschneiden, Schweißen sowie Kunststoffverarbeitung und Biegen."
    },
    services: {
      cta: "Mehr erfahren",
      laser: {
        title: "Laserschneiden",
        description: "Präzises Laserschneiden von Metall und Kunststoff in höchster Qualität.",
        details: "Das Laserschneiden von Materialien (Metall oder Kunststoff) erfolgt durch die direkte Einwirkung eines Hochleistungslaserstrahls auf das Material mit einem vorprogrammierten Muster.",
        features: [
          "Präzises Schneiden",
          "Schnelle Produktion",
          "Höchste Qualität"
        ]
      },
      products: {
        title: "Metall- und Kunststoffprodukte",
        description: "Herstellung von Metall- und Kunststoffprodukten für verschiedene Anwendungen.",
        details: "Wir produzieren verschiedene Metallprodukte für den allgemeinen Gebrauch, einschließlich lasergeschnittener, gepresster und gestanzter Teile, wärmebehandelter Stahlstücke. Wir stellen auch spritzgegossene Kunststoffteile für die weitere Verarbeitung und Veredelung sowie fertige spritzgegossene Kunststoffteile her.",
        features: [
          "Metallprodukte",
          "Kunststoffprodukte",
          "Maßgeschneiderte Lösungen"
        ]
      },
      tools: {
        title: "Werkzeuge und Formen",
        description: "Herstellung von Metallverarbeitungswerkzeugen und Kunststoffformen.",
        details: "Wir produzieren verschiedene Werkzeuge für die weitere Verarbeitung von Metall und Kunststoff, einschließlich Schneid- und Formwerkzeugen sowie Extrusionswerkzeugen. Wir stellen auch Spritzgussformen und Blasformen her.",
        features: [
          "Metallwerkzeuge",
          "Kunststoffformen",
          "Fachmännische Ausführung"
        ]
      }
    },
    process: {
      title: "Produktionsprozess",
      description: "Unser Produktionsprozess ist für maximale Effizienz und Qualität optimiert.",
      steps: [
        {
          title: "Design und Planung",
          description: "Detaillierte Planung und Produktdesign nach Ihren Spezifikationen."
        },
        {
          title: "Produktion",
          description: "Präzise Produktion mit den neuesten Technologien und Maschinen."
        },
        {
          title: "Qualitätskontrolle",
          description: "Strikte Qualitätskontrolle auf jeder Produktionsebene."
        }
      ]
    },
    cta: {
      title: "Bereit zur Zusammenarbeit?",
      description: "Kontaktieren Sie uns und erfahren Sie, wie wir bei der Entwicklung Ihrer Produkte helfen können.",
      button: "Kontaktieren Sie uns"
    }
  }
}; 