export const translations = {
  hr: {
    hero: {
      title: "O Microplet-u",
      description: "Iskustvo i kvaliteta u zavarivanju, obradi metala i izradi kompleksnih proizvoda."
    },
    story: {
      label: "O Nama",
      title: "Naša Priča",
      description1: "Tvrtku “Microplet” je 1990. godine osnovao Anto Barbarić, dipl.inž. strojarstva, koji je i njen vlasnik, kao obrt za izradu alata, preradu plastike i obradu metala.",
      description2: "Počela je kao maleni obiteljski obrt i kroz sve turbulentne godine koje su uslijedile u državi, “Microplet” se uspio održati i polagano, ali konzistentno rasti i razvijati se, tako da danas broji 26 zaposlena visokokvalificirana djelatnika, a svoje proizvode plasira diljem Republike Hrvatske, kao i izvan njenih granica.",
      description3: "“Microplet” je smješten u suvremenim prostorima usred industrijske zone u Pleternici i u svome radu koristi se najsuvremenijom tehnologijom i procesima izrade, u suradnji sa znanstvenim ustanovama i renomiranim tvrtkama u metalskoj industriji i preradi plastike, što jamči kvalitetu i pouzdanost proizvoda."
    },
    values: {
      title: "Naše Vrijednosti",
      subtitle: "Temeljne Vrijednosti",
      description: "Koje Nas Vode",
      intro: "Naše vrijednosti su temelj svakog projekta i svake odluke koju donosimo.",
      items: [
        {
          title: "Kvaliteta",
          description: "Posvećeni smo pružanju vrhunske kvalitete u svakom aspektu našeg poslovanja.",
          details: "Od dizajna do finalne proizvodnje, svaki detalj je pažljivo razmotren kako bismo osigurali najviše standarde kvalitete."
        },
        {
          title: "Partnerstvo",
          description: "Gradimo dugoročna partnerstva s našim klijentima.",
          details: "Razumijevamo vaše potrebe i pružamo personalizirana rješenja koja odgovaraju vašim specifičnim zahtjevima."
        },
        {
          title: "Inovacija",
          description: "Kontinuirano unapređujemo naše procese i tehnologije.",
          details: "Pratimo najnovije trendove u industriji i implementiramo inovativna rješenja kako bismo ostali na vrhuncu."
        }
      ]
    },
    expertise: {
      title: "Posvećenost kvaliteti",
      subtitle: "Obrada Metala i Plastike.",
      quality: "Vrhunska Kvaliteta i Preciznost.",
      details: "Specijalizirani smo za izradu alata za brizganje plastike, alata za puhanje, alata za štancanje, obradu metala, lasersko rezanje, zavarivanje te obradu plastike i savijanje."
    },
    services: {
      laser: {
        title: "Lasersko rezanje",
        description: "Precizno lasersko rezanje metala i plastike s vrhunskom kvalitetom.",
        details: "Lasersko rezanje materijala (metala ili plastike) se vrši usmjeravanjem laserskog mlaza velike izlazne snage direktno na materijal i uz pomoć unaprijed programiranog izgleda uzorka.",
        features: ["Precizno rezanje", "Brza izrada", "Vrhunska kvaliteta"]
      },
      products: {
        title: "Proizvodi od Metala i Plastike",
        description: "Proizvodnja metalnih i plastičnih proizvoda široke namjene.",
        details: "Proizvodimo različite metalne proizvode široke namjene, uključujući laserski rezane, prešane i štancane dijelove, toplinski obrađene komade čelika. Također proizvodimo injekcijske (brizgane) dijelove iz plastike namijenjene daljnjoj doradi i finaliziranju, kao i gotove brizgane plastične dijelove.",
        features: ["Metalni proizvodi", "Plastični proizvodi", "Prilagođena rješenja"]
      },
      tools: {
        title: "Alati i Kalupi",
        description: "Proizvodnja alata za obradu metala i kalupa za plastiku.",
        details: "Proizvodimo razne alate koji služe daljnjoj obradi metala i plastike, uključujući alate za prosijecanje i oblikovanje te alate za ekstruziju. Također izrađujemo kalupe za brizganje plastike i kalupe za puhanje.",
        features: ["Alati za metal", "Kalupi za plastiku", "Stručna izrada"]
      }
    },
    cta: {
      title: "Spremni za Suradnju?",
      description: "Kontaktirajte nas i saznajte kako možemo pomoći u razvoju vaših proizvoda.",
      button: "Kontaktirajte Nas"
    }
  },
  en: {
    hero: {
      title: "About Microplet",
      description: "Experience and quality in welding, metal processing, and the production of complex components."
    },
    story: {
      label: "About Us",
      title: "Our Story",
      description1: "The company “Microplet” was founded in 1990 by Anto Barbarić, a B.Eng. in Mechanical Engineering, who is also its owner, as a craft for making tools, processing plastics and metalworking.",
      description2: "It started as a small family craft and through all the turbulent years that followed in the country, “Microplet” managed to survive and slowly but consistently grow and develop, so that today it has 26 highly qualified employees, and it markets its products throughout the Republic of Croatia, as well as beyond its borders.",
      description3: "“Microplet” is located in modern premises in the middle of the industrial zone in Pleternica and in its work it uses the most modern technology and manufacturing processes, in cooperation with scientific institutions and renowned companies in the metal industry and plastic processing, which guarantees the quality and reliability of its products."
    },
    values: {
      title: "Our Values",
      subtitle: "Core Values",
      description: "That Guide Us",
      intro: "Our values are the foundation of every project and every decision we make.",
      items: [
        {
          title: "Quality",
          description: "We are committed to delivering premium quality in every aspect of our business.",
          details: "From design to final production, every detail is carefully considered to ensure the highest quality standards."
        },
        {
          title: "Partnership",
          description: "We build long-term partnerships with our clients.",
          details: "We understand your needs and provide personalized solutions that meet your specific requirements."
        },
        {
          title: "Innovation",
          description: "We continuously improve our processes and technologies.",
          details: "We follow the latest industry trends and implement innovative solutions to stay at the forefront."
        }
      ]
    },
    expertise: {
      title: "Commitment to Quality",
      subtitle: "Metal and Plastic Processing.",
      quality: "Premium Quality and Precision.",
      details: "We specialize in the production of injection moulds, blow moulds, stamping tools, metal processing, laser cutting, welding, as well as plastic processing and bending."
    },
    services: {
      laser: {
        title: "Laser Cutting",
        description: "Precise laser cutting of metals and plastics with premium quality.",
        details: "Laser cutting of materials (metal or plastic) is performed by directing a high-power laser beam directly onto the material using a pre-programmed pattern.",
        features: ["Precise cutting", "Fast production", "Premium quality"]
      },
      products: {
        title: "Metal and Plastic Products",
        description: "Production of metal and plastic products for various applications.",
        details: "We manufacture various metal products for general use, including laser-cut, pressed, and stamped parts, heat-treated steel components. We also produce injection-molded plastic parts for further processing and finishing, as well as finished injection-molded plastic parts.",
        features: ["Metal products", "Plastic products", "Custom solutions"]
      },
      tools: {
        title: "Tools and Molds",
        description: "Production of metal processing tools and plastic molds.",
        details: "We manufacture various tools for further processing of metals and plastics, including cutting and shaping tools, and extrusion tools. We also produce injection molding and blow molding molds.",
        features: ["Metal tools", "Plastic molds", "Expert manufacturing"]
      }
    },
    cta: {
      title: "Ready to Collaborate?",
      description: "Contact us and learn how we can help in developing your products.",
      button: "Contact Us"
    }
  },
  de: {
    hero: {
      title: "Über Microplet",
      description: "Erfahrung und Qualität in der Schweißtechnik, Metallverarbeitung und Herstellung komplexer Bauteile."
    },
    story: {
      label: "Über Uns",
      title: "Unsere Geschichte",
      description1: "Das Unternehmen „Microplet“ wurde 1990 von Anto Barbarić, einem Maschinenbauingenieur und zugleich Inhaber, als Handwerksbetrieb für die Werkzeugherstellung, Kunststoffverarbeitung und Metallbearbeitung gegründet.",
      description2: "Was als kleines Familienunternehmen begann, konnte trotz der turbulenten Jahre im Land bestehen und sich kontinuierlich weiterentwickeln. Heute beschäftigt „Microplet“ 26 hochqualifizierte Mitarbeiter und vertreibt seine Produkte nicht nur in ganz Kroatien, sondern auch darüber hinaus.",
      description3: "Das Unternehmen hat seinen Sitz in modernen Räumlichkeiten im Industriegebiet von Pleternica und arbeitet mit hochmoderner Technologie und Fertigungsverfahren. In Zusammenarbeit mit wissenschaftlichen Einrichtungen sowie namhaften Unternehmen der Metall- und Kunststoffbranche garantiert „Microplet“ höchste Qualität und Zuverlässigkeit seiner Produkte."
    },
    values: {
      title: "Unsere Werte",
      subtitle: "Kernwerte",
      description: "Die Uns Leiten",
      intro: "Unsere Werte sind die Grundlage jedes Projekts und jeder Entscheidung, die wir treffen.",
      items: [
        {
          title: "Qualität",
          description: "Wir sind bestrebt, in jedem Aspekt unseres Geschäfts Premium-Qualität zu liefern.",
          details: "Vom Design bis zur Endproduktion wird jedes Detail sorgfältig geprüft, um höchste Qualitätsstandards zu gewährleisten."
        },
        {
          title: "Partnerschaft",
          description: "Wir bauen langfristige Partnerschaften mit unseren Kunden auf.",
          details: "Wir verstehen Ihre Bedürfnisse und bieten personalisierte Lösungen, die Ihren spezifischen Anforderungen entsprechen."
        },
        {
          title: "Innovation",
          description: "Wir verbessern kontinuierlich unsere Prozesse und Technologien.",
          details: "Wir verfolgen die neuesten Branchentrends und implementieren innovative Lösungen, um an der Spitze zu bleiben."
        }
      ]
    },
    expertise: {
      title: "Qualitätsverpflichtung",
      subtitle: "Metall- und Kunststoffverarbeitung.",
      quality: "Premium Qualität und Präzision.",
      details: "Wir sind spezialisiert auf die Herstellung von Spritzgusswerkzeugen, Blasformen, Stanzwerkzeugen, Metallbearbeitung, Laserschneiden, Schweißen sowie Kunststoffverarbeitung und Biegen."
    },
    services: {
      laser: {
        title: "Laserschneiden",
        description: "Präzises Laserschneiden von Metallen und Kunststoffen in Premium-Qualität.",
        details: "Das Laserschneiden von Materialien (Metall oder Kunststoff) erfolgt durch die direkte Einwirkung eines Hochleistungslaserstrahls auf das Material mit einem vorprogrammierten Muster.",
        features: ["Präzises Schneiden", "Schnelle Produktion", "Premium Qualität"]
      },
      products: {
        title: "Metall- und Kunststoffprodukte",
        description: "Herstellung von Metall- und Kunststoffprodukten für verschiedene Anwendungen.",
        details: "Wir fertigen verschiedene Metallprodukte für den allgemeinen Gebrauch, einschließlich lasergeschnittener, gepresster und gestanzter Teile, wärmebehandelter Stahlkomponenten. Wir produzieren auch spritzgegossene Kunststoffteile zur weiteren Verarbeitung und Veredelung sowie fertige spritzgegossene Kunststoffteile.",
        features: ["Metallprodukte", "Kunststoffprodukte", "Maßgeschneiderte Lösungen"]
      },
      tools: {
        title: "Werkzeuge und Formen",
        description: "Herstellung von Metallverarbeitungswerkzeugen und Kunststoffformen.",
        details: "Wir fertigen verschiedene Werkzeuge für die weitere Verarbeitung von Metallen und Kunststoffen, einschließlich Schneid- und Formwerkzeuge sowie Extrusionswerkzeuge. Wir produzieren auch Spritzguss- und Blasformwerkzeuge.",
        features: ["Metallwerkzeuge", "Kunststoffformen", "Expertenherstellung"]
      }
    },
    cta: {
      title: "Bereit zur Zusammenarbeit?",
      description: "Kontaktieren Sie uns und erfahren Sie, wie wir bei der Entwicklung Ihrer Produkte helfen können.",
      button: "Kontaktieren Sie Uns"
    }
  }
}; 