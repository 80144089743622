import React from "react";
import { Link } from "react-router-dom";
import Transition from "./Transition";
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/home';
const galleryItems = [
  {
    src: "/images/Zavarivanje/v6.jpeg",
    span: "col-span-2"
  },
  {
    src: "/images/Laser/l8.jpeg",
    span: "col-span-3"
  },
  {
    src: "/images/Tokarenje/t6.jpeg",
    span: "col-span-2"
  },
  {
    src: "/images/Alati/a2.jpeg",
    span: "col-span-2"
  },
  {
    src: "/images/Tokarenje/t7.jpeg",
    span: "col-span-2"
  },
  {
    src: "/images/Brizganje/b1.jpeg",
    span: "col-span-3"
  },
  {
    src: "/images/Rezanje/r2.jpeg",
    span: "col-span-2"
  },
  {
    src: "/images/Glodalica/g1.jpeg",
    span: "col-span-3"
  }
];

const MosaicGallery = () => {
  const { language } = useLanguage();
  const t = translations[language];
  return (
    <div className="py-32">
      <div className="container mx-auto px-4 xl:max-w-[80%]">
        <Transition delay={0.2}>
          <div className="text-center mb-16">
            <span className="text-laser text-sm font-medium tracking-wider">
              {t.gallery.title}
            </span>
            <h2 className="text-[28px] md:text-[56px] font-[500] leading-[32px] md:leading-[64px] font-['Inter'] mt-6">
              <span className="block text-[#d6d6d6] [-webkit-text-fill-color:transparent] bg-[linear-gradient(270deg,#36383c,#fff_80%,#36383c)] [-webkit-background-clip:text] bg-clip-text">{t.gallery.subtitle}</span>
            </h2>
          </div>
        </Transition>

        <div className="space-y-1">
          {/* First Row */}
          <div className="flex flex-col md:flex-row gap-2 md:gap-1 md:h-[350px]">
            {galleryItems.slice(0, 4).map((item, index) => (
              <div
                key={index}
                className={`relative overflow-hidden h-[300px] md:h-[350px] rounded-xl ${item.span}`}
              >
                <img
                  src={item.src}
                  alt={`Gallery Image ${index + 1}`}
                  className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                />
              </div>
            ))}
          </div>

          {/* Second Row */}
          <div className="flex flex-col md:flex-row gap-2 md:gap-1 md:h-[350px]">
            {galleryItems.slice(4).map((item, index) => (
              <div
                key={index}
                className={`relative overflow-hidden h-[300px] md:h-[350px] rounded-xl ${item.span}`}
              >
                <img
                  src={item.src}
                  alt={`Gallery Image ${index + 4}`}
                  className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Gallery CTA */}
        <Transition delay={0.6}>
          <div className="text-center mt-16">
            <Link
              to={language === 'hr' ? '/gallery' : `/${language}/gallery`}
              className="group inline-flex items-center justify-center gap-3 px-10 py-5 text-lg font-medium rounded-full bg-gradient-to-r from-laser to-[#FF8A3C] text-white transition-all duration-500 shadow-[0_0_20px_rgba(255,94,7,0.25)] hover:shadow-[0_0_25px_rgba(255,94,7,0.35)] hover:scale-[1.02]"
            >
              <span>{t.gallery.cta}</span>
              <span className="transform transition-transform duration-500 group-hover:translate-x-2 text-xl">→</span>
            </Link>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default MosaicGallery;